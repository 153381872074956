import React from 'react';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import Container from 'react-bootstrap/Container';

export function IoT() {
    return (
        <Container fluid>
        <div className="App-home">
                <p id="core-concepts">
                IoT consists of communication from a device of any type (thus the generic term 'things') with a computer network. That network may have a static or intermittent connection to the Internet. On a micro scale we live with the Internet of Things [IoT] every day. Home thermostats, vacuums, cooking  and laundry appliances, televisions, gaming consoles and even robotic lawn mowers are just a few of the common items that can now be controlled from a phone or computer. However we benefit in ways we may not directly see when these technologies are used at scale in industries such as farming and manufacturing.</p>
                <p id="examples">The uses of IoT that are not as visible in daily life have a much greater impact on us than being able to run a vacuum while out to lunch. Large scale application of IoT to agriculture systems worldwide has changed not only large commercial farming operations, where the adoption of advanced technology solutions might be expected, but also aided small farming operations that are key to local food supplies. IoT sensor technology can be applied not only to machines that plant, feed and harvest crops but to the biological organisms - the living plants themselves. Much as you might use a wearable sensor in a fitness watch to track various aspects of your physical health, in the same way a plant can be monitored to see if it's well hydrated and properly nourished.
                <br />
                The transformation of agriculture through technology is not just a matter of profitability or academic interest. Per <a href="https://www.forbes.com/sites/forbesbusinesscouncil/2022/06/16/five-use-cases-for-iot-and-edge-cloud-technology-in-smart-farming/?sh=2a5cfc9a89dc" target="_blank">Johnathan Seelig in Forbes</a> in a little more than 15 years as the world's population nears 10 billion people the calories needed to sustain the population will increase by 70% while water supplies and arable land availability will be significantly descreased.
                <br />
                The technologies supporting IoT must adeptly transform to meet demand, as the proliferation of sensors communicating data must be supported by communications networks in order to collect, analyze and take actions based on sensor inputs.
                </p>
                <p id="edge-computing">Edge computing...</p>
                <p id="platforms">Platforms...</p>

        </div>
        </Container>
    );
}