import React from 'react';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import full from './media/tomato-plant-full.png';
import dead from './media/tomato-plant-dead.png';
import buggy from './media/tomato.png';


export function Home () {

    return (
    <div>
        <Container fluid>
        <p className='App-home'>In Northern California everything grows, this tomato plant was a volunteer from a previous year's garden. Unfortunately someone pulled the plug on the watering system when I was away for a bit. Wouldn't it have been great to be alerted before this prolific tomato producer died an untimely death?</p>

        <Carousel>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100 App-home-carousel-images"
                    src={full}
                    alt="Healthy tomato plant"
                />
                <Carousel.Caption>
                <p>Looking good!</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100 App-home-carousel-images"
                    src={buggy}
                    alt="Buggy tomato plant"
                />
                <Carousel.Caption>
                <p>Free salad for the bugs</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100 App-home-carousel-images"
                    src={dead}
                    alt="RIP poor tomato plant"
                />
                <Carousel.Caption>
                <p>
                    Died of thirst...
                </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
        </Container>
        </div>
    );
}

export default Home;