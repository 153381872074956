import React from 'react';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import Container from 'react-bootstrap/Container';

export function About() {
    return (
        <Container fluid>
            <div className='App-home'>
                <p>Garden Glorious (or GG) is a site to document the practical application of technology to gardening. As the site grows it will also document how the technologies demonstrated may be applied work at a much larger scale.
                </p>
                <p>I work at a lovely company, <a href="https://avanade.com">Avanade</a>, where I participate in a special interest group [SIG] dedicated to advancing knowledge of IoT (Internet of Things) supporting IoT projects in industries such as energy, agriculture and manufacturing. I've had prior exposure to the use and value of IoT at an agriculture client that managed growing conditions for farms across the United States by means of field sensors. All major cloud providers offer enterprise scale IoT solutions and Azure certifies IT Architects and Engineers who specialize in the technology. My small POC and the larger one I am constructing with the group will focus on Microsoft Azure IoT. Secondarily, this site will show how to accomplish similar goals on AWS Greengrass.
                </p>
                <p>If you're interested in getting help with IoT, Edge Computing and Analytics and similar technologies reach out to me on <a href="https://linkedin.com/in/ramonamaxwell">LinkedIn</a> and I will be happy to put you in touch with the right people at <a href="https://avanade.com">Avanade</a>.
                </p>
        </div>
        </Container>
    );
}