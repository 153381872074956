import React from 'react';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';


export function Gear() {
    return (
        <Container fluid>
            <CardGroup>
                <Card bg="danger" text="white" className="mb-2 App-home-carousel-images">
                    <Card.Img variant="top" src="holder.js/100px160" />
                    <Card.Body>
                        <Card.Title>Grow Tent</Card.Title>
                        <Card.Text>
                            Description of item ...
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card bg="primary" text="white" className="mb-2 App-home-carousel-images">
                    <Card.Img variant="top" src="holder.js/100px160" />
                    <Card.Body>
                        <Card.Title>Lighting</Card.Title>
                        <Card.Text>
                            This is a longer card with supporting text below as a natural
                            lead-in to additional content. This content is a little bit
                            longer.
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card bg="success" text="white" className="mb-2 App-home-carousel-images">
                    <Card.Img variant="top" src="holder.js/100px160" />
                    <Card.Body>
                        <Card.Title>Ventilation</Card.Title>
                        <Card.Text>
                            This is a longer card with supporting text below as a natural
                            lead-in to additional content. This content is a little bit
                            longer.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </CardGroup>
        </Container>
    );
}