import React from 'react';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import Container from 'react-bootstrap/Container';

export function Dashboard() {
    return (
        <Container fluid>
            <div className="App-home">
                <p>Dashboard ... </p>
            </div>
        </Container>
    );
}