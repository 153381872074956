import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import thing from './media/thing.png';
import './App.scss';
import { Home } from './Home';
import { Gear } from './Gear';
import { IoT } from './IoT';
import { Dashboard } from './Dashboard';
import { About } from './About';
import { Development } from './Development';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";


export function Navigation() {

    return (
        <Router>
        <Navbar className="Navbar Navbar-expand-lg Navbar-light Navbar-primary">
            <Container fluid>
                <Navbar.Brand href="/App">
                    <img
                        alt="missing thing"
                        src={thing}
                        className="contain"
                    />{' '}
                    Garden Glorious
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="NavbarColor03">
                    <Nav activeKey={window.location.pathname} className="mr-auto">
                        <Nav.Link href="/Home">Home</Nav.Link>
                        <Nav.Link href="/Dashboard">Dashboard</Nav.Link>
                        <Nav.Link href="/Development">Development Journal</Nav.Link>
                        <Nav.Link href="/IoT">IoT Concepts</Nav.Link>
                        <Nav.Link href="/Gear">Gear</Nav.Link>
                        <Nav.Link href="/About">About</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
            <Routes>
                    <Route path="/Home" element={<Home />} />
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/Development" element={<Development />} />
                    <Route path="/IoT" element={<IoT />} />
                    <Route path="/Gear" element={<Gear />} />
                    <Route path="/About" element={<About />} />
            </Routes >
        </Router>
        
    )   
} 

export default Navigation;