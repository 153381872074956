import React from 'react';
import logo from './media/gg.jpg';
import "bootswatch/dist/spacelab/bootstrap.min.css";
import { Navigation } from './Navigation';


//TODO: integrate runbook references in about
//TODO: test SVG with links

function App() {   
    return (
    <div className="App">
      <header className="App-header" id="logo-title">
          <div><img src={logo} className="App-logo" alt="logo" /></div>
          <div><h1 className='App-title'>Garden Glorious</h1>
          <p className='lead App-subtitle'>Make your garden glorious with the aid of technology</p>
          </div>
      </header>
      <div> 
        <Navigation />
      </div>
      <div id="footer" className='App-footer'><p>Project Director and Site Owner <a href="https://linkedin.com/in/ramonamaxwell">Ramona Maxwell</a> &copy;2022 - 2023 All Rights Reserved</p></div>
      </div>
  );
}

export default App;
